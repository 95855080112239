@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter';
  overflow: hidden;
  text-decoration: none;
  color: inherit;
}

.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
}

.invert-color-scheme {
  background-color: #111;
  color: #fff0c4;
}

.regular.color.scheme {
  background-color: white;
  color: #111;
}

.invert {
  height: 30px;
  width: 30px;
}

.container {
  max-width: 90%;
  display: flex;
  flex-direction: column;
}

.infosection {
  margin-bottom: 30px;
}

.links {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 50px;
  width: 100%;
}

.link {
  margin-right: 30px;
}

.darkmode {
  color: blue;
}

.info {
  display: flex;
  align-items: center;
}

.title {
  /* width: 100%; */
  padding-bottom: 30px;
  font-weight: bold;
  font-size: 2.4rem;
}

.grade {
  white-space: pre;
  font-weight: 300;
}

.tag {
  opacity: 0.5;
  font-weight: 300;
}